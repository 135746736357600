.loginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  /* height: 100vw; */
  /* background: url("../images/background-main-mobile2.jpg"); */
  /* background-size: contain; */
}

.loginForm {
  background-color: #000000b9;
  box-shadow: 0px 8px 32px 0 rgba(0, 0, 0, 0.3);
  padding: 30px;
  color: white;
  position: absolute;
  top: 20%;
}

.loginForm label {
  color: white !important;
}
